@import "../../../style/theme";

.sendInvitations-btn {
  margin-right: 16px;
}

.table-with-selection {
  margin-top: 15px;
  .ant-table-bordered .ant-table-thead > tr > th:first-child,
  .ant-table-bordered .ant-table-tbody > tr > td:first-child {
    border-right: none;
  }
}

.profile-row-select.ant-select {
  margin-bottom: 16px;
  width: 150px;
}

.DraftEditor-editorContainer {
  height: 230px;
  padding: 15px;
  overflow: auto;
  margin-top: 17px;
  border-radius: 4px;
  border: solid 1px @separator-color;

  .align-right div {
    text-align: right;
  }
  .align-center div {
    text-align: center;
  }
  .align-left div {
    text-align: left;
  }
}

.template-toolbar {
  width: 100%;

  &_line {
    margin-top: 10px;
  }

  &_select {
    &.ant-select {
      width: 150px;
      margin-right: 10px;
    }

    &_color {
      vertical-align: middle;
      margin-right: 5px;
      width: 20px;
      height: 10px;
      display: inline-block;
      border: 1px solid grey;
    }
  }
}

.template-toolbar_button {
  &.ant-btn {
    padding: 2px 0;
    width: 28px;
    height: 28px;
    margin-right: 4px;
    border-radius: 4px;
    border: solid 1px @separator-color;
  }
  .anticon {
    height: 12px;
    vertical-align: middle;
  }
  &__active.ant-btn {
    color: @blue-text;
    border-color: @blue-text;
    .anticon svg path {
      fill: @blue-text;
    }
  }
}

.template-buttons-panel {
  margin-top: 20px;
  &_btn {
    width: 140px;
    margin-right: 10px;
  }
}

.template-static-data {
  margin-top: 20px;
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
  }
  &_item {
    min-width: 284px;
  }
  &_title {
    width: 120px;
    margin-right: 10px;
    display: inline-block;
  }
  &_value {
    width: 153px;
    display: inline-block;
  }
  &_block {
    margin-bottom: 15px;
    p {
      margin-bottom: 0;
    }
  }
}
