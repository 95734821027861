@import '../../style/theme';

.not-found-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-text {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 15px;
}
.not-found-title{
  font-size: 70px;
  line-height: 1;
  margin-bottom: 20px;
}

.not-found-link {
  text-align: left;
  font-size: 16px;
  color: #4b8ecb;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
