@import "../../style/theme";

.table-with-action {
  .action-btn {
    border-right: 1px solid #ffffff;
  }
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    &:nth-last-child(2) {
      border-right: none;
    }
    &:last-child .editable-row-operations {
      opacity: 0;
      text-align: right;
    }
  }

  .ant-table-thead > tr.ant-table-row-hover > td,
  .ant-table-tbody > tr.ant-table-row-hover > td,
  .ant-table-thead > tr:hover > td,
  .ant-table-tbody > tr:hover > td {
    &:nth-last-child(1) {
      .editable-row-operations {
        opacity: 1;
      }
    }
  }

  .hidden {
    display: none;
  }
  .visible {
    display: block;
  }

  .ant-table-bordered .ant-table-thead,
  .ant-table-bordered .ant-table-tbody {
    > tr {
      cursor: pointer !important;
      > th,
      > td {
        font-size: .8rem;
        line-height: 19px;
      }
      > td {
        //color: @blue-text;
      }
      > .col-drag-and-drop {
        border-right: none;
      }
      .ant-table-expand-icon-th,
      .ant-table-row-expand-icon-cell {
        width: 10px;
        min-width: 10px;
        padding: 7px 10px;
        border-right: none;
        color: rgba(0, 0, 0, 0.65);
      }
      .ant-table-row-expand-icon {
        border: none;
        font-size: 8px;
        line-height: 25px;
        position: relative;
        background: transparent;
      }
    }
    > .ant-table-expanded-row-level-1 {
      > td {
        padding-top: 0;
        padding-bottom: 0;
      }
      &:hover > td {
        background: transparent;
      }
      .ant-table-placeholder {
        border: none;
        background: transparent;
      }
      table {
        border: none;
      }
      td td {
        border: none;
        padding: 7px 16px;
        div {
          color: rgba(0, 0, 0, 0.65);
        }
      }
      .ant-table-row-level-0 .col-drag-and-drop .editable-row-operations {
        opacity: 1;
      }
      &:hover .ant-table-row-level-0 {
        .editable-row-operations {
          opacity: 0;
        }
        .col-drag-and-drop .editable-row-operations {
          opacity: 1;
        }
        &:hover > td .editable-row-operations {
          opacity: 1;
        }
      }
    }
  }
}

.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  background: transparent;
}

.ant-table-bordered .ant-table-bordered .ant-table-thead > tr > th {
  border-right: none;
}

.add-question {
  .ant-modal-body {
    padding-top: 15px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-radio-group {
    width: 100%;
    label {
      width: 25%;
      text-align: center;
    }
  }
}

.action-btn,
.action-btn-single {
  font-weight: 600;
  color: @dark-blue;
  font-size: .8rem;
  cursor: pointer;
  word-break: normal;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
  i {
    font-size: 16px;
    margin-right: 5px;
  }
  &-is-disabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    &:hover {
      color: currentColor;
    }
  }
}

.action-btn {
  &:extend(.action-btn-single);
  margin-right: 10px;
  padding-right: 10px;
  &:last-child {
    border: none;
  }
}

