@import '../../style/theme';

.table-drag-and-drop {
  .col-drag-and-drop {
    padding: 7px 12px;
  }

  .ant-table-bordered .ant-table-tbody tr{
    &.drop-over-downward td {
      border-bottom: 2px dashed #1890ff !important;
    }
    &.drop-over-upward td {
      border-top: 2px dashed #1890ff !important;
    }
  }
  .ant-table-placeholder {
    font-size: 12px;
  }
}

.icon-drag-drop,
.table-drag-and-drop .icon-drag {
  cursor: move;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
}

.icon-drag:before,
.icon-drag-drop:before {
  content: url('../../images/icons/icon-dragndrop.svg');
  width: 10px;
  height: 10px;
}
