@import '../../style/theme';
@pink-picker-selected: #fcb8d0;
@orange-picker-selected: #fbccae;
@yellow-picker-selected: #f5ec79;
@blue-selected: #b8c1ff;
@dark-green-picker-selected: #50e3c2;
@lime-picker-selected: #b8e986;
@red-picker-selected: #fa808f;
@violet-picker-selected: #c07afe;
@dark-blue-picker-selected: #84adff;

.carousel {
  &_wrapper {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.item {
  position: relative;
  background: rgba(255, 255, 255, 0.45);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_container {
    position: relative;
    min-width: 30px;
    border-width: 8px 2px 2px;
    border-style: solid;
    user-select: none;
    box-sizing: content-box;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_title {
    display: table;
    writing-mode: vertical-rl;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0;
    font-family: PingFangSC-Medium;
    font-size: 0.9rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  &_open {
    display: flex;
    overflow-x: hidden;
    height: 100%;
    min-height: 100%;
    min-width: 0;
    width: 0;
    border-style: solid;
    transition: min-width 0.5s ease;
  }

  &_close {
    &.ant-btn-circle.ant-btn-sm,
    .ant-btn-circle.ant-btn-sm {
      top: 5px;
      right: 4px;
      width: 18px;
      height: 18px;
      font-size: 0.7rem;
      position: absolute;
      border-color: @primary-color;
      color: @primary-color;
      background: transparent;

      &:hover {
        color: @text-color;
        background: transparent;
        border-color: @text-color;
      }

      &:disabled {
        color: @text-color;
        border-color: @text-color;
      }
    }
  }
}

.color-picker {
  position: absolute;
  bottom: 3px;
  right: 3px;
  .wrapper {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    .orange {
      background-color: @orange-picker-selected;
    }
    .pink {
      background-color: @pink-picker-selected;
    }
    .yellow {
      background-color: @yellow-picker-selected;
    }
    .blue {
      background-color: @blue-selected;
    }
    .dark-green {
      background-color: @dark-green-picker-selected;
    }
    .lime {
      background-color: @lime-picker-selected;
    }
    div {
      position: absolute;
      transform-origin: 100% 100%;
      background: #ccc;
      width: 30px;
      height: 30px;
      left: 50%;
      top: 50%;
      margin-top: -30px;
      margin-left: -30px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
