/* OSCE COLORS */
/*
 reset antd
*/
.login_wrapper {
  height: 100vh;
}
.login_block {
  padding: 20px;
  height: 560px;
  width: 1000px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}
.login_block_wrapper {
  height: 100%;
}
.login_block_right {
  padding-left: 20px;
  border-left: 1px solid #eeeeee;
  height: 100%;
}
.login_forgot_link {
  font-size: 0.9rem;
  color: #548dcd;
}
.login_title {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 30px;
}
.login_max_width {
  width: 100%;
}
.login_form {
  width: 70%;
}
.login_languages {
  color: #548dcd;
  font-size: 0.8rem;
  line-height: 36px;
}
