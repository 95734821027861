.main-layout {
  &[class*="ant-layout"] {
    height: 100%;
    padding: 16px;
    background: #fff;
    min-height: calc(~"100vh - 98px");
  }
  &_content {
    padding: 0 24px;
    overflow: auto;
    width: 100%;
  }
}