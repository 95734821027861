@import '../theme';

.ant-table table {
  font-size: 0.8rem;
}

.ant-layout-content {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 10px;
    word-break: break-word;
  }
}

.ant-table .ant-input {
  font-size: 12px;
}

.ant-table tr.drop-over-downward td {
  border-bottom: 2px dashed @dark-blue;
}

.ant-table tr.drop-over-upward td {
  border-top: 2px dashed @dark-blue;
}

.ant-table-thead > tr > th {
  font-weight: bold;
  > span {
    white-space: nowrap;
  }
}

.ant-table-bordered .ant-table-thead > tr > th {
  font-weight: bold;
  > span {
    white-space: nowrap;
  }
}

.sp-selection_patients .ant-table-bordered .ant-table-thead > tr > th,
.student-results .ant-table-bordered .ant-table-thead > tr > th {
  font-weight: bold;
  > span {
    white-space: normal;
  }
}

.table-with-action {
  .ant-table-thead > tr > th.ant-table-column-sort {
    background: #fafafa;
  }
  .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1 > td,
  .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1 > td {
    padding-left: 0;
    padding-right: 0;
  }
}

.table-expandable-no-first-td
  .ant-table-expanded-row.ant-table-expanded-row-level-1
  > td:first-child {
  padding: 0;
  width: 0;
}

.table-button_centered {
  padding-right: 25px;
}
