@import '../theme';

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: initial;
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0;
}

.ant-tabs.ant-tabs-no-animation {
  overflow: initial;
}
