@import '../../../style/theme';

.m-10 {
  margin: 10px;
}

.role {
  &_panel_line {
    border-bottom: solid 1px @border-color;
    margin-bottom: 0;
  }
  &_name {
    height: 21px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    line-height: 1.17;
    display: inline-block;
    margin-left: 20px;
  }
  &_form .card-item {
    .ant-form-item-label {
      width: 240px;
    }
    .ant-form-item-control-wrapper {
      width: calc(~'100% - 240px');
    }
  }
  &_general {
    &_header {
      height: 21px;
      opacity: 0.57;
      font-size: 0.8rem;
      line-height: 1.75;
    }
  }
  &_date-picker-separator {
    width: 24px;
    height: 28px;
    position: relative;
    vertical-align: top;
    display: inline-block;
    &:before {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 2px;
      width: 10px;
      position: absolute;
      background: @separator-color;
    }
  }
  &_search-btn {
    margin-left: 15px;
    vertical-align: top;
  }
}

.role_definition .ant-table-bordered .ant-table-tbody {
  > .ant-table-row-level-0 {
    cursor: default !important;
    td:last-child {
      text-align: right;
    }
  }
  .role_item {
    cursor: pointer;
    color: @dark-blue;
    &:hover {
      color: darken(@dark-blue, 10%);
    }
  }
}

form .ant-input-group .ant-select.select_studyYear {
  width: 100%;
}
