
::-webkit-scrollbar {
  overflow: visible;
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  border-width: 4px 6px;
}

::-webkit-scrollbar-thumb:vertical {
  border-width: 6px 4px;
}

::-webkit-scrollbar-track-piece {
  background: #ababab;
  border-radius: 3px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border: solid #f0f2f5;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  border: solid #e4e4e4;
}