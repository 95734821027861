@import '../../../../style/theme';

.header-filter-md {
  margin-right: 20px;
  .ant-select,
  .ant-input {
    width: 160px;
  }
}

.patient-questionnaire-header {
  margin-bottom: 16px;
}

.sections-list-sidebar {
  width: 160px;
  padding-left: 30px;
  margin-left: auto;
}

.maxheight {
  height: calc(~'100vh - 342px');
  overflow-y: auto;
}

.questions-list {
  position: relative;
  flex-grow: 1;
  border-left: @border-style;
  border-bottom: @border-style;
  word-break: break-word;
  .maxheight();
}

.sections-list {
  min-width: 160px;
  width: 160px;
  border-left: @border-style;
  border-bottom: @border-style;
  border-right: @border-style;
  .maxheight();
  .ant-menu {
    margin-left: -1px;
  }
  .ant-menu-inline {
    border-right: 0;
  }
}

.questions-sidebar .ant-menu-item:after {
  left: 1px;
  right: auto;
}

.ant-checkbox-group.question {
  display: block;
}

.question-row {
  border-bottom: @border-style;
  padding: 15px;
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    flex-shrink: 0;
    height: 30px;
    margin-right: 10px;
  }
}

.question-divider {
  height: 10px;
}

.questions-title {
  color: rgba(0, 0, 0, 0.35);
}

.questions-answer-row {
  display: flex;
  width: 100%;
}

.question-text {
  margin-bottom: 8px;
  margin-right: 10px;
  white-space: pre-line;
}

.answer-text-btn-last {
  margin-left: 5px;
}

.answer-btns-hover {
  opacity: 0;
  transition: opacity 0.15s;
  display: inline-block;
  margin: -3px 0;
}

.question-row:hover .answer-btns-hover {
  opacity: 1;
}

.comment-opened {
  display: inline;
  width: 300px;
}

.comment-opened ~ .answer-text-container .answer-btns-hover {
  display: none;
}

.answer-text-container {
  font-weight: 600;
  white-space: pre-line;
}

.comment-text {
  display: inline-flex;
  color: @label-color;
  font-size: 12px;
  line-height: normal;
  cursor: pointer;
  white-space: pre-line;
  &:hover {
    color: @primary-color;
    text-decoration: underline;
  }
}

.comment-row {
  margin-bottom: 10px;
}
