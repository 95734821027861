.card-student {
  border-radius: 0;
}
.card-student:first-of-type .ant-card-body {
  padding-left: 0;
}
.card-student .ant-card-meta {
  margin-bottom: 15px;
}
.card-col-padding.ant-col {
  padding-left: 15px;
}
