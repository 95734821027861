@import "../../style/theme.less";

.header {
  z-index: 1;
  position: relative;
  background: @violet;
  height: @header-height;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  &_block[class*="ant-menu"] {
    line-height: @header-height;
    background: transparent;
    border: none;
    .ant-menu-item {
      opacity: 0.8;
      border: none;
      padding: 0 18px;
      &:hover {
        opacity: 1;
        color: #fff;
        border: none;
      }
      > a {
        color: #fff;
        font-size: 0.9rem;
        position: relative;
        margin: 0 -18px;
        padding: 0 18px;
        &:hover {
          color: #fff;
        }
      }
    }
    .ant-menu-item-selected {
      opacity: 1;
      font-weight: 500;
      > a:after {
        content: '';
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        display: block;
        background: #fff;
        position: absolute;
      }
    }
  }
  &_right {
    display: flex;
    align-items: center;
    height: @header-height;
    &.ant-dropdown-trigger {
      padding: 0;
    }
    &_logout {
      color: #fff;
      cursor: pointer;
      font-size: 12px;
      border-left: 1px solid #fff;
      height: 40px;
      &:hover {
        opacity: 0.8;
      }
      i {
        margin-right: 10px;
      }
      p {
        margin-bottom: 0px;
      }
      &_username {
        height: 21px;
        font-size: 0.8rem;
        margin-top: -15px;
        line-height: 1.5;
      }
      &_roles {
        height: 21px;
        font-size: 0.7rem;
        line-height: 1.75;
        opacity: 0.8;
      }
    }
  }
  .navbar-top-links > li {
    padding: 0 30px;
    line-height: 40px;
    > a {
      padding: 0;
      font-size: 0.8rem;
      &:hover {
        color: #fff;
        text-decoration: none;
        background: transparent;
      }
    }
  }
}

.popover-header span {
  margin: 5px 0;
  display: block;
  font-size: 0.8rem;
}