@import "../../../../style/theme";

.station {
  padding-bottom: 24px;
  padding-top: 14px;
  &_header {
    color: @text-color-secondary;
  }
  &_item {
    width: 50%;
    line-height: 30px;
    margin-bottom: 5px;
    &_name {
      width: 185px;
      vertical-align: top;
      display: inline-block;
      color: @text-color-secondary;
    }
    &_type {
      width: 150px;
      position: relative;
      display: inline-block;
      &:hover {
        .card-btns-container_hover {
          opacity: 1;
        }
      }
      .ant-select {
        width: 100%;
      }
    }
    &_remove {
      cursor: pointer;
      color: @blue-text;
      margin-right: 5px;
      line-height: 24px;
      &:hover {
        opacity: .8;
      }
    }
    .card-btns-container {
      right: -75px;
      button:first-of-type {
        margin-right: 5px;
      }
    }
  }
  &_action-btn {
    margin-top: 10px;
  }
}

.basic-data-separator {
  border-bottom: solid 1px @light-grey;
  margin: 20px 0;
}

.basic-data-block .card-editable-width .ant-form-item-control .ant-spin-container > span {
  width: 110px;
}
