@import "../../../../style/theme";

.station {
  &_regular:not('.station_sp_paused') {
    min-width: 210px;
  }
  &_double:not('.station_sp_paused') {
    min-width: 245px;
    .station_row:nth-child(2) .column .cell:first-child {
      border-top: none;
    }
  }
  &_double .students.hasEndBreak .break-cell {
    width: 60px;
  }
  &_sp_paused {
    border-right: 0;
    .cell {
      border-right: 0;
    }
    .simulated-patient.hasEndBreak .break-cell,
    .simulated-patient.hasStartBreak .break-cell{
      width: 100%;
    }
    .simulated-patient:first-child {
      .cell {
        border-left: none;
      }
    }
  }
  &_preparation {
    .station_row:nth-child(2) .column:first-of-type .cell:first-of-type {
      border-top: none;
    }
  }
  .column .cell:first-of-type {
    border-top: 1px solid @light-grey;
  }
  .station_row:last-child .cell:last-child {
    border-bottom: none;
  }
}
