@import '../../../style/theme';

.osce-profile {
  &-name {
    height: 21px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    line-height: 1.17;
    display: inline-block;
    margin-left: 17px;
    margin-right: 17px;
    &_wrapper {
      user-select: none;
    }
  }
  &-is-freezed {
    height: 21px;
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
    line-height: 1.17;
    display: inline-block;
    margin-left: 17px;
    margin-right: 17px;
    color: red;
  }
}

.osce-settings-card {
  .ant-switch {
    margin-left: 12px;
  }
  .switcher-wrapper {
    line-height: 32.9999px;
  }
}

.osce-semester-select {
  width: 160px;
}

.osce-students__attendance {
  width: 100%;
  cursor: pointer;
  text-align: center;
}

.osce-day_manual_add-day.ant-btn {
  margin: 0 auto;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @primary-color;
  border-color: @primary-color;
  border-radius: 0;
  > i.anticon {
    width: 20px;
    height: 20px;
    border: 1px solid;
    line-height: 22px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  + span {
    margin-left: 0;
    line-height: 14px;
    white-space: normal;
  }
}

.import-block {
  > div:first-child {
    border-right: 1px solid @light-grey;
  }
  &_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  &_alert.ant-alert {
    padding: 16px 16px 16px 57px;
    font-size: 14px;
    font-weight: 500;

    .ant-alert-icon {
      top: 16px;
    }
  }
  &_list-files {
    list-style: none;
    padding-left: 20px;
    margin-top: 20px;
    li {
      font-size: 14px;
      margin: 10px 0;
      .anticon {
        margin-left: -20px;
        margin-right: 10px;
      }
    }
  }
  .table-panel_btn {
    margin-top: 16px;
  }
}

.refresh-btn {
  margin-left: 10px;
}

.bell-system-expanded-row {
  margin-left: -10px;
  margin-top: -10px;
}
