.table-panel {
  margin-bottom: 16px;
  padding-top: 10px;
  &_btn {
    font-weight: 500;
    vertical-align: top;
  }
  &_filter {
    margin-left: 24px;
  }
  &_btn:not(:last-child) {
    margin-right: 24px;
  }
  &_search {
    width: 160px;
  }
  .ant-input-affix-wrapper {
    width: 160px;
  }
  &_btn-right {
    float: right;
    margin-right: 0;
  }
  &_btn-right.freeze-btn {
    margin-right: 24px;
  }
  &_print-btn {
    margin-right: 20px;
  }
  &:after {
    content: '';
    clear: both;
    display: table;
  }
}
