@import "../../../style/theme";

.table-panel_filter_open {
  color: @dark-blue;
  border-color: @dark-blue;
}

.table-panel_filter:not(.table-panel_filter_open){
  color: @text-color;
  border-color: @separator-color;
}