@import "../theme";

.table-row-pointer tr {
  cursor: pointer;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.inline-flex {
  display: flex;
}

.inline-flex-center {
  display: flex;
  align-items: center;
}

.grey-header {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: @grey;
  border: solid 1px @light-grey;
  padding: @space-big;
  font-size: @font-size-base;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
}

.search-input {
  width: 100%;
}

.btn-primary_add {
  font-weight: 500;
  .anticon {
    font-weight: 600;
  }
}

.horizontal-line {
  border-bottom: solid 1px @light-grey;
}

.form-header-grey {
  height: 21px;
  opacity: 0.57;
  font-size: .8rem;
  line-height: 1.75;
}

.editable-time-popup.ant-time-picker-panel-narrow {
  .ant-time-picker-panel-input-wrap {
    max-width: initial;
    width: 144px;
  }
  .ant-time-picker-panel-select {
    width: 72px;
  }
}
