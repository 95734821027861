/* OSCE COLORS */
/*
 reset antd
*/
.sidebar {
  background: transparent;
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
  font-size: 0.9rem;
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item > a:hover {
  color: #4b8ecb;
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-weight: 500;
  background: #e9f3ff;
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: #4b8ecb;
}
.sidebar .ant-menu-inline .ant-menu-item:after {
  border-color: #548dcd;
}
