@import "../../../style/theme";

.osce-settings-card {
  width: 100%;
  border-radius: 0;
  .ant-switch-checked {
    background-color: @green;
  }
  .ant-card-body {
    padding-left: 0;
  }
  &_custom-header {
    .ant-form-item-label {
      text-align: left;
      label:after {
        content: '';
      }
    }
  }
  &_border {
    border-bottom: solid 1px @light-grey;
  }
  &_header-name {
    height: 21px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    line-height: 1.17;
    display: inline-block;
  }
  .ant-card-meta {
    margin-bottom: 15px;
  }
  .ant-checkbox-wrapper {
    display: block;
    height: 32px;
    margin-bottom: 9px;
    margin-top: 9px;
  }
}
