@import './theme.less';

html,
body {
  font-size: 16px;
  min-width: 290px;
  -ms-overflow-style: scrollbar;
}
body {
  background-color: #e7ebee;
  font-family: @font-family-no-number;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
}

@media (min-width: @desktop) {
  html {
    font-size: @desktop-fontSize;
  }

  .ant-btn,
  .ant-input,
  .ant-input-number,
  .ant-input-number-input,
  .ant-select-selection--single,
  .role_date-picker-separator,
  .ant-time-picker-input {
    height: 32px;
  }

  .ant-select-selection__placeholder {
    line-height: 24px;
  }

  .ant-select-selection__rendered {
    line-height: 30px;
  }

  .circuit .osce-day_manual {
    &_sequence .osce-day_cut_wrapper {
      width: 155px;
    }

    &_manual_btns-wrapper button {
      width: 130px;
    }
  }

  .card-item {
    .ant-form-item-label {
      width: @desktop-item-width;
    }
    .ant-form-item-control-wrapper {
      width: calc(~'100% - ' @desktop-item-width);
    }
  }

  .circuit .osce-day_manual_btns-wrapper {
    width: 135px;
  }
}

.flex-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.flex-row {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.effect .navbar-content,
.effect #navbar,
.effect #content-container,
.effect #mainnav-container,
.effect #aside-container,
.effect .navbar-brand,
.effect .navbar-content,
.effect .navbar-header:before,
.mainnav-container,
.ant-menu-dark.ant-menu-inline,
.left-contant,
.gform .groups,
.operates {
  transition-property: width, max-height, padding, left, right;
  transition-duration: 0.35s;
}

#navbar {
  position: absolute;
  width: 100%;
  height: @g-header-height;
  z-index: 10;
  background-color: #e7ebee;
  left: 0;
  top: 0;
}
#navbar-container {
  box-shadow: 0 2px 0px -1px rgba(0, 0, 0, 0.05);
  background-color: #373d41;
}
.navbar-header {
  float: left;
  left: 0;
  position: relative;
  z-index: 100;
}
.navbar-content {
  position: relative;
  left: 0;
  background-color: #373d41;
  padding: 0 8px;
}
.navbar-brand {
  background-color: transparent;
  color: #fff;
  padding: 0;
  height: @g-header-height;
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 320px;
  display: block;
}
.navbar-brand:hover,
.navbar-brand:focus {
  color: #fff;
}
.brand-title {
  display: block;
  line-height: @g-header-height;
}
.brand-text {
  display: block;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 5px;
  text-align: left;
  padding-left: 15px;
}
.navbar-top-links .dropdown-menu-md {
  display: none;
}
.navbar-top-links > li {
  float: left;
}
.navbar-top-links > li > a {
  display: table-cell;
  padding: 0 12px;
  vertical-align: middle;
  height: @g-header-height;
  color: #ddd;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.navbar-top-links > li > a:focus {
  background-color: transparent;
}
.navbar-top-links > li > a:hover {
  background-color: #f2f2f2;
  color: #2b2b2b;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.navbar-top-links > .open > a,
.navbar-top-links > .open > a:focus {
  background-color: #f2f2f2;
  color: #515151;
}
.navbar-top-links {
  float: right;
}
.ant-dropdown-menu-item {
  font-size: 0.8rem;
}

#container {
  height: auto;
  position: relative;
  min-width: 290px;
  overflow: hidden;
  -webkit-transition: opacity 0.5s linear 1.5s;
  transition: opacity 0.5s linear 1.5s;
}
#container.boxed-layout {
  background-color: #cfd3d6;
}
#content-container {
  position: relative;
  padding-top: @g-header-height;
  padding-left: @g-left-width-base;
  background-color: #e7ebee;
}
.page-header {
  border: 0 none;
  color: #7c7c7c;
  font-size: 2.2em;
  font-weight: 100;
  margin: 0;
  padding: 10px 0;
}
.page-header .label {
  margin-right: 0.5em;
}
#page-content {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.nav-dropmenu {
  border: 1px solid #ccc;
  .ant-dropdown-menu-item {
    width: 280px;
    .label {
      white-space: nowrap;
      margin: 0 10px 0 0;
    }
  }
}
.ant-table-pagination {
  text-align: right;
}

.qqbicon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: transparent;
  background-image: url(../images/default.png);
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: middle;
  margin-right: 10px;
}
.qqbicon-navcontrol {
  background-image: url(../images/navcontrol.png);
}
.LeftNav-control {
  background-color: #4a5064;
  text-align: center;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  .qqbicon {
    margin: 0;
  }
  &:hover {
    background-color: #4c577b;
  }
}

.boxed-mini {
  #content-container {
    padding-left: @g-left-width-mini;
  }
  .main {
    .left-contant {
      left: @g-left-width-mini;
    }
  }
}
.LeftNavMini .mainnav-container {
  width: @g-left-width-mini;
  .ant-menu-dark.ant-menu-inline {
    width: @g-left-width-mini;
  }
  .menu-name {
    display: none;
  }
}

//.ant-tabs-bar{ margin-bottom: 0;}
/*login.start*/
.sy_top {
  height: 30%;
  width: 100%;
  background: #2d333e;
  position: absolute;
  top: 0;
}
.btmLogin {
  width: 100%;
  top: 30%;
  position: absolute;
}
.sy_bottom {
  text-align: center;
  .ant-form {
    width: 280px;
    margin: 0 auto;
    .has-error {
      text-align: left;
    }
  }
}
.sy_bottom h1 {
  font-size: 25px;
  color: #333;
  font-weight: normal;
  padding: 50px 0;
}
.sy_bottom li {
  display: inline-block;
  margin-right: 10px;
}
.companyName {
  position: fixed;
  width: 100%;
  color: #ccc;
  bottom: 10px;
  text-align: center;
  font-size: 16px;
}
.ul-wrap .ant-btn {
  margin-right: 10px;
}
.ul-wrap .ant-input {
  height: 32px;
}
.ul-wrap .ant-spin-spinning {
  left: 0;
}
/*login end*/
.hide {
  display: none;
}
.gform input {
  width: auto;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: inherit;
}
.root,
#container,
.boxed,
#content-container,
#page-content {
  height: 100%;
}
.page {
  padding: @space-big @space-big 0 @space-big;
  background-color: #fff;
}
#page-content,
.page,
.page .ant-spin-nested-loading,
.page .ant-spin-container,
.page .search-form-table,
.page .ant-table-wrapper,
.page .ant-spin-nested-loading,
.page .ant-spin-container,
.page .ant-table,
.page .ant-table-content,
.page .ant-table-scroll,
.page .ant-table-body {
  .flex-column;
}

.ant-time-picker-panel-select ul {
  padding-bottom: 0 !important;
}

.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner-full {
  margin-top: -0.55em;
}

.ant-checkbox-wrapper {
  font-size: 0.8rem;
}
.ant-tabs-nav .ant-tabs-tab {
  font-size: 0.9rem;
}
.ant-card {
  font-size: 0.8rem;
}
.ant-form label,
.ant-form-item {
  font-size: 0.8rem;
}
.radio-buttons {
  margin: 15px 25px;
  .ant-radio-group {
    display: flex;
    width: 100%;
  }
  .ant-radio-button-wrapper {
    text-align: center;
    flex-grow: 1;
  }
}
