@import '../../style/theme';

.criteria {
  &_panel_btns {
    margin-bottom: 16px;
    .ant-btn {
      margin-right: 15px;
    }
  }
  &_table {
    .ant-table-bordered .ant-table-thead > tr th:first-child,
    .ant-table-bordered .ant-table-tbody > tr td:first-child {
      border-right: none;
      position: relative;
    }
    &_operation {
      top: 0;
      left: 0;
      bottom: 0;
      width: 39px;
      height: auto;
      display: flex;
      color: @dark-blue;
      position: absolute;
      align-items: center;
      justify-content: center;
      background: @light-grey;
    }
    &_value {
      margin-left: 45px;
    }
    .ant-select-selection-selected-value {
      height: 32px;
      white-space: pre-line;
    }
  }
  &_popover {
    width: 270px;
    > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-inner {
      background-color: @grey;
    }
    .ant-select,
    .ant-input-group .ant-select,
    .ant-input-number {
      width: 100%;
    }
  }

  &-form {
    .ant-input-group {
      display: inline-block;
    }
    .ant-input-affix-wrapper {
      margin-top: 2px;
    }
    .add-btn {
      color: #fff;
      background: @dark-blue;
      border-color: @dark-blue;
      display: block;
      margin: 0 auto;
      font-weight: bold;
    }
  }

  .bottom {
    display: flex;
    margin-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid @border-color;
  }

  .hide-button {
    cursor: pointer;
    color: @dark-blue;
    font-size: 12px;
    font-weight: 500;

    .icon {
      margin-right: 7px;
    }
  }
}
