.patient-status-switcher {
  width: 100px;
  cursor: pointer;
  .ant-spin-container {
    display: flex;
    align-items: center;
  }
  .ant-switch {
    margin-right: 8px;
  }
  .switcher-text {
    font-size: 0.9rem;
  }
}

.additional-header {
  display: flex;
  align-items: center;
  &-buttons {
    margin-left: 84px;
    display: inline-flex;
    justify-content: space-between;
    min-width: 180px;
    .ant-btn {
      min-width: 80px;
      margin-left: 15px;
    }
  }
}
