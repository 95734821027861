@import "../../../../style/theme";

.catalog-container {
  display: inline-block;
  margin: 10px;
  width: 500px;
  min-height: 240px;
  border-radius: 2px;
  border: @border-style;
}

.catalogs-container {
  margin: -5px -10px -10px;
}

.catalog-name {
  padding: @general-pad;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: @label-header;
  border-bottom: @border-style;
}

.catalog-tags {
  background-color: @grey;
  min-height: 126px;
  padding: @general-pad;
  .ant-tag {
    margin-bottom: 10px;
    &:hover {
      color: @primary-color;
      border-color: @primary-color;
    }
  }
}

.catalog-add {
  padding: 15px 20px;
}
