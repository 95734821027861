@import "../../style/theme";

@scissor-border: #979797;
@grey-background: #f7f7f7;

.circuit {
  margin-top: 15px;
  .station {
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    border: solid 1px @preview-border;
    background: white;
    margin-right: 4px;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    transition: opacity 0.1s;
    &_regular:not('.station_sp_paused') {
      min-width: 175px;
    }
    &_double:not('.station_sp_paused') {
      min-width: 245px;
    }
    &_break, &_sp_paused {
      .cell, .column, .break-cell {
        width: 100%;
      }
    }
    &_break {
      .cell, .column {
        border-right: 0;
      }
    }
    &_sp_paused {
      border-right: 0;
      .cell {
        border-right: 0;
      }
      .break-cell {
        border-right: 0;
      }
    }
    &_row {
      width: 100%;
      display: flex;
      background: @light-blue;
      &:last-child .cell:last-child {
        //border-bottom: 0;
      }
    }
    &_new {
      .circuit_section_header {
        border: none;
      }
      &_content {
        padding: 0 15px;
      }
    }
    &_circuit {
      min-height: 202px;
    }
    &-double,
    &-preparation {
      .editable-wrapper {
        max-width: 50%;
        min-width: 50%;
      }
    }
  }

  .editable-wrapper {
    padding: 0 20px;
    width: 100%;
    .select-section {
      width: 100%;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .card-btns-container {
      top: -5px !important;
      right: -51px !important;

      .ant-btn:first-child {
        margin-right: 0px;
      }
    }
    .no-edit-label {
      display: inline-block;
      cursor: pointer;
      overflow: hidden;
      word-break: break-word;
      width: 100%;
      font-family: Helvetica;
      font-size: .9rem;
      font-weight: normal;
      font-style: oblique;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.45);
      max-height: 19px;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
  }
  .circuit_section {
    &_header {
      min-height: 51px;
      padding-left: 22px;
      padding-top: 7px;
      position: relative;
      border-bottom: 1px solid @preview-border;
      h3 {
        font-size: .9rem;
        color: rgba(0, 0, 0, 0.65);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        margin-bottom: 0;
        line-height: normal;
        letter-spacing: normal;
      }
      p {
        margin-bottom: 8px;
        color: @text-color-secondary;
      }
      &_close {
        position: absolute;
        top: 0;
        right: 0;
        &.ant-btn-circle,
        .ant-btn-circle {
          top: 7px;
          right: 10px;
          width: 16px;
          height: 16px;
          font-size: 0.6rem;
          position: absolute;
          border-color: @primary-color;
          color: @primary-color;
          &:hover {
            color: @text-color;
            border-color: @text-color;
          }
          &:disabled {
            color: @text-color;
            border-color: @text-color;
          }
        }
      }
      //+ .break .break-cell {
      //  border-right: 1px solid @light-grey;
      //}
    }
  }
  .osce {
    &-day {
      &_sequence {
        display: flex;
        min-height: 140px;
        background-color: @white;
        border-bottom: solid 1px @cut-border;
      }
      &_sequences-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 162px;
      }
      &_cut {
        &_wrapper {
          width: 60px;
          min-width: 56px;
          border: solid 1px @cut-border;
          border-bottom: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          p {
            margin-bottom: 0;
            font-size: .9rem;
            font-weight: 500;
          }
        }
        &_scissor-wrapper {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          border: solid 1px @scissor-border;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            cursor: pointer;
            font-size: 23px;
          }
        }
      }
      &_expander {
        width: 20%;
      }
      &-header {
        display: flex;
        height: 77px;
        justify-content: space-between;

        &_left {
          display: flex;
          width: 60%;
        }
        &_prepone-button {
          min-width: 221px;
        }
        &_lunch-button {
          min-width: 115px;
        }
        &_right {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          div {
            button {
              margin-right: 10px;
            }
          }
        }
        &_circuit {
          border: solid 1px @cut-border;
          background-color: @grey-background;
        }
        &_block {
          display: flex;
          align-items: center;
          width: 140px;
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p {
            margin-bottom: 0;
          }
          &-short {
            width: 80px;
          }
          &-short-time {
            width: 100px;
          }
        }
        &_text-wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 16px;
          padding-bottom: 16px;
        }
        &_main-name-block {
          width: 130px;
          flex-direction: row;
          justify-content: center;
        }
        &_name {
          font-size: 1rem;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
        }
        &_small {
          &-name {
            font-weight: normal;
            line-height: normal;
          }
          &-value {
            font-size: .9rem;
            font-weight: 500;
          }
        }
        &_manual {
          .osce-day-header_left {
            width: 90%;
          }
          .osce-day_expander {
            width: 10%;
          }
          .ant-calendar-picker {
            width: 110px;
            margin-top: 7px;
          }
          .ant-time-picker {
            width: 100px;
            margin-top: 7px;
          }
          &_block {
            .osce-day-header_block {
              width: auto;
            }
          }
        }
      }
      &_manual {
        &_sequence {
          .osce-day_cut_wrapper {
            min-width: 135px;
            padding: 0 10px;
            position: relative;
            align-items: flex-start;
          }
        }
        &_btns-wrapper {
          width: 115px;
          button {
            width: 100%;
            text-align: left;
          }
        }
        &_add-circuit {
          height: 100%;
          &.ant-btn,
          .ant-btn {
            width: 65px;
            height: 100%;
            border-radius: 0;

            &:not(:disabled) {
              color: @primary-color;
              border-color: @primary-color;
            }

            &:disabled {
              height: 100%;
            }

            > i.anticon {
              width: 20px;
              height: 20px;
              border: 1px solid;
              line-height: 22px;
              border-radius: 50%;
              text-align: center;
              margin-bottom: 10px;

              + span {
                display: block;
                margin-left: 0;
                line-height: .9rem;
                white-space: normal;
              }
            }
          }
        }
        &_break-duration {
          height: 60px;
          display: flex;
          align-items: center;
          border: 1px solid @preview-border;
          border-top: 0;

          &-block {
            padding: 10px;
            margin-left: 10px;
            border-radius: 5px;
            background: white;
            width: 170px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid @preview-border;
          }

          &-value {
            font-weight: bold;
          }
        }
      }
    }
  }

  .ant-collapse {
    .ant-collapse-item {
      background: @grey-background;
      border: 1px solid @grey-background;
      border-radius: 8px 8px 0 0;
      margin-bottom: 20px;
      .ant-collapse-header {
        padding: 0;
        .arrow {
          top: 16px;
          color: @dark-blue;
        }
      }

      .ant-collapse-content {
        padding: 0;
        margin-bottom: 0;

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }
}

.list-breaks {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  li {
    line-height: 22px;
  }
}

.break-block {
  margin: 16px 0;
  padding: 15px 15px 0;
  border: 1px solid #e8e8e8;
  border-width: 1px 0;
  .ant-form-item-label {
    text-align: left;
    margin-right: 10px;
  }
  .ant-input-number {
    width: 90px;
  }
}

.panel_btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  .ant-btn {
    margin-left: 10px;
  }
}
