@import '../../../../style/theme';

.relative-column {
  position: relative;
  border-right: none !important;
}

.criteria {
  &_panel_btns {
    margin-bottom: 16px;
    .ant-btn {
      margin-right: 15px;
    }
  }
  &_table {
    .ant-table-bordered .ant-table-thead > tr th:first-child,
    .ant-table-bordered .ant-table-tbody > tr td:first-child {
      border-right: none;
      position: relative;
    }
    &_operation {
      top: 0;
      left: 0;
      bottom: 0;
      width: 60px;
      height: auto;
      display: flex;
      color: @dark-blue;
      position: absolute;
      align-items: center;
      justify-content: center;
      background: @light-grey;
    }
    &_value {
      margin-left: 65px;
    }
    .ant-select {
      width: 100%;
    }
    &_select.ant-select {
      width: 70%;
    }
  }
  &_popover {
    width: 360px;
    > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-inner {
      background-color: @grey;
    }
    .ant-select,
    .ant-input-group .ant-select,
    .ant-input-number {
      width: 100%;
    }
    .blueBtn {
      width: 63px;
      display: block;
      margin: 0 auto;
      font-weight: bold;
    }
  }
}
