.card {
  &-student {
    border-radius: 0;
    &:first-of-type .ant-card-body {
      padding-left: 0;
    }
    .ant-card-meta {
      margin-bottom: 15px;
    }
  }
  &-col-padding.ant-col {
    padding-left: 15px;
  }
}
