@import '../../../../../style/theme';

.annotation {
  &_wrapper {
    padding-top: 6px;
  }
  &_rectangle {
    display: inline-block;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
  }
  &_text {
    vertical-align: top;
    margin-left: 10px;
    display: inline-block;
    line-height: 28px;
  }
  &_item-wrapper {
    padding-top: 10px;
  }
  &_rotation-break {
    background-color: @violet;
  }
  &_lunch-break {
    background-color: @blue-text;
  }
  &_coffee-break {
    background-color: @green;
  }
}

.preview {
  min-width: 290px;
  background-color: #ffffff;
  margin-right: 16px;
  border: solid 1px @preview-border;

  &_header {
    height: 50px;
    line-height: 50px;
    font-size: 0.9rem;
    font-weight: 500;

    &-item {
      border: 1px solid #e9e9e9;
      padding-left: 10px;
      padding-right: 10px;

      &-time {
        text-align: center;
      }
    }
  }
}

.days-container {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
}

.slot {
  height: 30px;
  min-width: 120px;
  font-size: 0.9rem;
  line-height: 30px;
  padding: 10px;
  &_break {
    text-align: center;
    color: #fff;
    min-height: 5px;
  }
  &_logical-mark {
    margin-left: 16px;
  }
  &.time_slot {
    text-align: center;
  }
  &.start_time {
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
  }
}
