@import '../theme';

.ant-btn {
  font-size: 0.8rem;
  padding: 0 9px;
}

.ant-input, .ant-select, .ant-select-dropdown, .ant-radio-group, .ant-collapse, .card-item .ant-form-item-label, .ant-time-picker-input, .ant-menu-item-group-title {
  font-size: 0.8rem;
}

.ant-modal-title {
  font-size: 1rem;
}

.ant-tabs { font-size: .8rem }

.ant-btn:hover,
.ant-btn:focus {
  color: @dark-blue;
  border-color: @dark-blue;
}

.ant-input:focus {
  border-color: @dark-blue;
}

.ant-table-fixed-header .ant-table-scroll div.ant-table-header {
  overflow: hidden;
  margin-right: 6px;
}

.ant-spin-container {
  overflow: initial;
}

.ant-table-scroll {
  .ant-table-header {
    margin-right: 0 !important;
  }
  .ant-table-body {
    margin-top: 6px;
    overflow-y: hidden !important;
  }
  .scrollarea {
    height: calc(~"100vh - 250px");
  }
  .scrollarea-content {
    border-left: 1px solid #e8e8e8;
  }
}

.ant-btn.ant-popover-open {
  color: @dark-blue;
  border-color: @dark-blue;
}

.ant-radio-wrapper {
  white-space: pre-line;
}

.ant-popover-inner {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

.ant-input-number {
  width: 110px;
}

.ant-form-item-label label {
  white-space: normal;
  display: inline-block;
}

.hidden {
  display: none;
}

.ant-message-notice-content {
  max-width: 600px;
}

.ant-confirm-body {
  display: flex;
}

.ant-popover {
  max-width: 442px;
}

.ant-message-custom-content.ant-message-error span {
  white-space: pre-line;
}