@import "../../style/theme";
@border: solid 1px @border-color;

.clinic {
  &_header {
    display: inline;
    height: 21px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    line-height: 1.17;
  }
  &_panel_line {
    border-bottom: @border;
  }
  &_btn {
    margin-bottom: 9px;
  }
  &_general {
    &_key {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
    }
    &_header {
      height: 21px;
      opacity: 0.57;
      font-size: .8rem;
      line-height: 1.75;
    }
  }
  &_select-clinic[class*="ant-select"] {
    width: 160px;
    margin-left: 24px;
    vertical-align: top;
  }
}

.examiner {
  &-card-row {
    border-bottom: @border;
    display: flex;
    .card-editable-width .ant-form-item-control .ant-select,
    .card-editable-width .ant-form-item-control .ant-input {
      width: 125px;
    }
  }
  &-edit-header {
    margin-bottom: 0;
  }
  &_create-new {
    padding-bottom: 100px;
  }
}

.clinic_panel_line .ant-form-item {
 display: inline-flex;
}
