/* OSCE COLORS */
/*
 reset antd
*/
.table-with-action .action-btn {
  border-right: 1px solid #ffffff;
}
.table-with-action .ant-table-bordered .ant-table-thead > tr > th:nth-last-child(2),
.table-with-action .ant-table-bordered .ant-table-tbody > tr > td:nth-last-child(2) {
  border-right: none;
}
.table-with-action .ant-table-bordered .ant-table-thead > tr > th:last-child .editable-row-operations,
.table-with-action .ant-table-bordered .ant-table-tbody > tr > td:last-child .editable-row-operations {
  opacity: 0;
  text-align: right;
}
.table-with-action .ant-table-thead > tr.ant-table-row-hover > td:nth-last-child(1) .editable-row-operations,
.table-with-action .ant-table-tbody > tr.ant-table-row-hover > td:nth-last-child(1) .editable-row-operations,
.table-with-action .ant-table-thead > tr:hover > td:nth-last-child(1) .editable-row-operations,
.table-with-action .ant-table-tbody > tr:hover > td:nth-last-child(1) .editable-row-operations {
  opacity: 1;
}
.table-with-action .hidden {
  display: none;
}
.table-with-action .visible {
  display: block;
}
.table-with-action .ant-table-bordered .ant-table-thead > tr,
.table-with-action .ant-table-bordered .ant-table-tbody > tr {
  cursor: pointer !important;
}
.table-with-action .ant-table-bordered .ant-table-thead > tr > th,
.table-with-action .ant-table-bordered .ant-table-tbody > tr > th,
.table-with-action .ant-table-bordered .ant-table-thead > tr > td,
.table-with-action .ant-table-bordered .ant-table-tbody > tr > td {
  font-size: 0.8rem;
  line-height: 19px;
}
.table-with-action .ant-table-bordered .ant-table-thead > tr > .col-drag-and-drop,
.table-with-action .ant-table-bordered .ant-table-tbody > tr > .col-drag-and-drop {
  border-right: none;
}
.table-with-action .ant-table-bordered .ant-table-thead > tr .ant-table-expand-icon-th,
.table-with-action .ant-table-bordered .ant-table-tbody > tr .ant-table-expand-icon-th,
.table-with-action .ant-table-bordered .ant-table-thead > tr .ant-table-row-expand-icon-cell,
.table-with-action .ant-table-bordered .ant-table-tbody > tr .ant-table-row-expand-icon-cell {
  width: 10px;
  min-width: 10px;
  padding: 7px 10px;
  border-right: none;
  color: rgba(0, 0, 0, 0.65);
}
.table-with-action .ant-table-bordered .ant-table-thead > tr .ant-table-row-expand-icon,
.table-with-action .ant-table-bordered .ant-table-tbody > tr .ant-table-row-expand-icon {
  border: none;
  font-size: 8px;
  line-height: 25px;
  position: relative;
  background: transparent;
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1 > td,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1 > td {
  padding-top: 0;
  padding-bottom: 0;
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1:hover > td,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1:hover > td {
  background: transparent;
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1 .ant-table-placeholder,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1 .ant-table-placeholder {
  border: none;
  background: transparent;
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1 table,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1 table {
  border: none;
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1 td td,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1 td td {
  border: none;
  padding: 7px 16px;
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1 td td div,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1 td td div {
  color: rgba(0, 0, 0, 0.65);
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1 .ant-table-row-level-0 .col-drag-and-drop .editable-row-operations,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1 .ant-table-row-level-0 .col-drag-and-drop .editable-row-operations {
  opacity: 1;
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1:hover .ant-table-row-level-0 .editable-row-operations,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1:hover .ant-table-row-level-0 .editable-row-operations {
  opacity: 0;
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1:hover .ant-table-row-level-0 .col-drag-and-drop .editable-row-operations,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1:hover .ant-table-row-level-0 .col-drag-and-drop .editable-row-operations {
  opacity: 1;
}
.table-with-action .ant-table-bordered .ant-table-thead > .ant-table-expanded-row-level-1:hover .ant-table-row-level-0:hover > td .editable-row-operations,
.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1:hover .ant-table-row-level-0:hover > td .editable-row-operations {
  opacity: 1;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  background: transparent;
}
.ant-table-bordered .ant-table-bordered .ant-table-thead > tr > th {
  border-right: none;
}
.add-question .ant-modal-body {
  padding-top: 15px;
}
.add-question .ant-form-item {
  margin-bottom: 10px;
}
.add-question .ant-radio-group {
  width: 100%;
}
.add-question .ant-radio-group label {
  width: 25%;
  text-align: center;
}
.action-btn,
.action-btn-single,
.action-btn {
  font-weight: 600;
  color: #548dcd;
  font-size: 0.8rem;
  cursor: pointer;
  word-break: normal;
  user-select: none;
}
.action-btn:hover,
.action-btn-single:hover {
  opacity: 0.8;
}
.action-btn i,
.action-btn-single i {
  font-size: 16px;
  margin-right: 5px;
}
.action-btn-is-disabled,
.action-btn-single-is-disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.action-btn-is-disabled:hover,
.action-btn-single-is-disabled:hover {
  color: currentColor;
}
.action-btn {
  margin-right: 10px;
  padding-right: 10px;
}
.action-btn:last-child {
  border: none;
}
