@import "../../style/theme";

.information-icon {
  color: @blue-text;
  line-height: 28px;
  margin-right: 20px;
}

.information-popover{
  max-width: 300px;
}