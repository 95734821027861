/* OSCE COLORS */
/*
 reset antd
*/
.annotation_wrapper {
  padding-top: 6px;
}
.annotation_rectangle {
  display: inline-block;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
}
.annotation_text {
  vertical-align: top;
  margin-left: 10px;
  display: inline-block;
  line-height: 28px;
}
.annotation_item-wrapper {
  padding-top: 10px;
}
.annotation_rotation-break {
  background-color: #424767;
}
.annotation_lunch-break {
  background-color: #4b8ecb;
}
.annotation_coffee-break {
  background-color: #3dbd7d;
}
.preview {
  min-width: 290px;
  background-color: #ffffff;
  margin-right: 16px;
  border: solid 1px #e9e9e9;
}
.preview_header {
  height: 50px;
  line-height: 50px;
  font-size: 0.9rem;
  font-weight: 500;
}
.preview_header-item {
  border: 1px solid #e9e9e9;
  padding-left: 10px;
  padding-right: 10px;
}
.preview_header-item-time {
  text-align: center;
}
.days-container {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
}
.slot {
  height: 30px;
  min-width: 120px;
  font-size: 0.9rem;
  line-height: 30px;
  padding: 10px;
}
.slot_break {
  text-align: center;
  color: #fff;
  min-height: 5px;
}
.slot_logical-mark {
  margin-left: 16px;
}
.slot.time_slot {
  text-align: center;
}
.slot.start_time {
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}
