@import "../../style/theme";

@border: solid 1px @border-color;

.footer {
  &_max_width {
    width: 100%;
  }
}
.form {
  &_content {
    padding: 7px 25px 15px;
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: rgba(0, 0, 0, 0.65);
    }
    .ant-checkbox-wrapper {
      display: block;
      font-size: 0.8rem;
      margin-bottom: 15px;
    }
    .ant-checkbox-group {
      margin-bottom: -15px;
    }
    .ant-form-item-label label {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 500;
    }
  }
  &_header {
    height: 21px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.17;
  }

  &-tabs .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }

  &-full-width .ant-form-item-control{
    width: 100%;
  }
}

.card-for-table {
  border-radius: 0;
  .ant-card-body {
    padding: 0;
  }
  .ant-card-meta {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
.card-border-vertical {
  width: 1px;
  border-right: @border;
}
.ant-form-item-with-help {
  margin-bottom: 0;
}

.card-editable-width .ant-form-item-control {
  width: auto;
  display: inline-block;
  max-width: 70%;
  vertical-align: top;
  .ant-spin-container > span {
    width: 100%;
  }
  .ant-select, .ant-input {
    width: 220px;
    max-width: 100%;
  }
}

.card-no-editable {
  display: inline-flex;
  cursor: default;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.85);
  //max-width: 77%;  check whether possible without it
  overflow: hidden;
  word-break: break-word;
}

.deleted {
  align-self: center;
  &-circle {
    margin-left: 15px;
    width: 7px;
    height: 7px;
    border-radius: 50px;
    background: @red;
  }
  &-text {
    margin-left: 7px;
    color: @red;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
  }
}

.card-double-margin.ant-form-item {
  margin-right: 7px;
  cursor: pointer;
}

.ant-form-item-children .ant-spin-container {
  display: flex;
  flex-wrap: wrap;
}
