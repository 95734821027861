@import "../../../../style/theme";

.checklist-td-other {
  position: relative;
  &.v-space {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.checklist-td-other .editable-row-operations {
  padding-right: 15px;
  > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    .action-btn {
      margin: 0;
      padding: 0;
    }
  }
}

.table-with-action .ant-table-bordered .ant-table-tbody > .ant-table-expanded-row-level-1 td .checklist-expanded-row td.col-drag-and-drop {
  padding-right: 0;
}

.checklist-icon-drag.icon-drag {
  display: table-cell;
  padding: 10px;
  .anticon {
    height: 1.1em;
  }
}

.checklist-white-td {
  background: white;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 0;
  right: 0;
  border: @border-style;
  border-left: 0;
  height: auto;
}

.checklist-expanded-row .ant-table-tbody td {
  position: relative;
  &:first-of-type .checklist-white-td {
    border-left: 1px solid #e8e8e8;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    left: 15px;
  }
  &:last-of-type .checklist-white-td {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    right: 15px;
  }
}

.checklist-text {
  font-size: .8rem;
  color: rgba(0, 0, 0, 0.65);
  margin-right: 10px;
}
.checklist-comment {
  font-size: .8rem;
  color: rgba(0, 0, 0, 0.3);
}
.checklist-option {
  border-bottom: @border-style;
  margin: 0 -16px;
  padding: 10px 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 280px;
  &:last-child {
    border-bottom: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.checklist-tags {
  margin: 5px -5px -5px;
}

.checklist-tag {
  margin: 5px;
  font-size: .7rem;
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  border: solid 1px #668ad3;
  color: #668ad3;
  font-weight: 500;
  min-height: 22px;
  line-height: 1.15;
  padding: 5px 8px;
  background: white;
  .anticon-info-circle-o {
    margin-left: 5px;
  }
}

.add-section {
  &-body-wrapper {
    display: flex;
    flex-flow: wrap column;
  }
  &-buttons {
    margin: 15px;
   .ant-radio-button-wrapper {
     width: 50%;
     text-align: center;
   }
  }
}

.modal-d-n-d-item {
  border-radius: 4px;
  background-color: #f7f7f7;
  border: @border-style-dark;
  padding: 6px 0;
  .icon-drag {
    margin: 0 10px;
  }
}

.comment-icon {
  cursor: pointer;
  margin-top: 11px;
  transition: transform 0.15s;
  &.open {
    transform: rotate(180deg);
  }
}

.ant-form-item-mb-0 .ant-form-item {
  margin-bottom: 0;
}

.modal-draggable-container-tags {
  display: flex;
  overflow: auto;
}

.checklistQuestion-modal .modal-draggable-option {
  margin-bottom: 10px;
  .ant-input-number {
    width: 100%;
  }
}

.modal-full-width-checkbox {
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  .ant-radio-button-wrapper {
    flex-grow: 1;
    text-align: center;
  }
}

.preview-panel {
  margin: 16px 36px 0;
  &_title {
    font-size: 18px;
    font-weight: bold;
    color: black;
    display: inline-block;
  }
}
