@import '../../../../style/theme';

@border: solid 1px @border-color;
@height: 310px;

.sp-dropdown-null-value-fix {
  min-width: 100px;
}

.patient-container {
  display: flex;
  min-height: 2 * @height;

  .image-col {
    width: 160px;
    margin-right: 24px;
  }
  .avatar-uploader .ant-upload.ant-upload-drag {
    width: 136px;
    height: 136px;
  }
  .card-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
    object-fit: cover;
  }
  .action-btn {
    font-size: 0.8rem;
    margin: 5px auto 5px 20px;
    display: block;
    padding: 0;
  }

  .ant-spin-nested-loading {
    width: 100%;
  }
}

.card-row {
  border-top: @border;
  display: flex;
}
.form-card {
  min-height: 310px;
  width: 50%;
  border-radius: 0;
  &:first-of-type .ant-card-body {
    padding-left: 0;
  }
  .ant-card-meta {
    margin-bottom: 15px;
  }
}
.card-border-vertical {
  width: 1px;
  border-right: @border;
}

.card-username {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  position: relative;
  display: inline-table;
  height: auto;
  margin: 0 0 9px;
  padding-right: 40px;
  .ant-spin-container {
    display: flex;
    align-items: flex-start;
    min-height: 32px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-control {
    line-height: 30px;
  }
}

.card-name-separator {
  width: 14px;
  display: inline-block;
  line-height: 32px;
  margin-bottom: -5px;
  margin-left: 1px;
}

@item-width: 140px;

.card-btns-container_hover {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  min-height: 26px;
  text-align: right;
  padding-right: 4px;
  margin-left: 5px;
  opacity: 0;
  transition: opacity 0.2s;
}

.card-item {
  position: relative;
  .ant-form-item-label {
    width: @item-width;
    text-align: left;
    font-size: 14px;
  }
  .ant-form-item-control-wrapper {
    width: calc(~'100% - ' @item-width);
  }
  .ant-form-item-control {
    min-height: 32px;
  }
  .ant-form-item-children {
    display: flex;
  }
}

.card-username,
.card-item,
.ant-form-item {
  &:hover .card-btns-container_hover {
    opacity: 1;
  }
}

.card-form-number .card-item-editable .ant-form-item-control-wrapper {
  width: calc(~'70% - ' @item-width);
  .ant-input-number {
    width: 100%;
    margin: 0;
  }
}

.card-item-static-width .ant-form-item-control {
  width: 440px;
  max-width: calc(~'100% - 40px');
  .ant-spin-container > span {
    width: 100%;
  }
}

.profile-card-btns-container {
  display: flex;
  margin-left: 10px;
  align-items: center;
  .ant-btn:first-child {
    margin-right: 5px;
  }
  .anticon {
    transform: translateY(0.5px);
  }
}

.card-btns-container {
  display: flex;
  position: absolute;
  right: -60px;
  top: 4px;
  .ant-btn:first-child {
    margin-right: 5px;
  }
  .anticon {
    transform: translateY(0.5px);
  }
}

.card-static-label {
  display: block;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.85);
  word-break: break-all;
  word-break: break-word;
}

.card-static-name {
  font-size: 1rem;
}

.card-editable-language {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  width: 440px;
  max-width: 100%;
  position: relative;
  .ant-select {
    width: calc(~'50% - 5px');
  }
  .ant-btn {
    border: none;
  }
}

.card-language-delete {
  position: absolute;
  right: -20px;
  color: @primary-color;
}

.card-language-container {
  .card-btns-container {
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 55px;
  }
  .card-no-editable {
    flex-direction: column-reverse;
  }
}

.card-language-spacer {
  width: 15px;
}

.patient-tabs {
  overflow: initial;
  border-top: solid 1px @light-grey;
}
