@import "../../style/theme.less";

.sidebar {
  background: transparent;
  .ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item {
      font-size: 0.9rem;
      > a:hover {
        color: @blue-text;
      }
    }
    .ant-menu-item-selected {
      font-weight: 500;
      background: @light-blue;
      a {
        color: @blue-text;
      }
    }
  }
  .ant-menu-inline .ant-menu-item:after {
    border-color: @dark-blue;
  }
}