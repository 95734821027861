@import "../../../style/theme";

.sp-selection {
  .table-panel_btn {
    margin-right: 15px;
    &:first-of-type {
      margin-left: 15px;
    }
    &:hover {
      color: @dark-blue;
      border-color: @dark-blue;
    }
    &:focus {
      color: @text-color;
      border-color: @separator-color;
    }
  }
  &_assign {
    width: 160px;
    display: block;
    overflow: hidden;
    color: @text-color;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &_patients {
    margin-bottom: 17px;
    .action-btn {
      margin: 0;
      padding: 0;
      border-right: none;
      font-weight: normal;
      display: inline-block;
    }
    .ant-table-tbody > tr > td:nth-last-child(1) .editable-row-operations.focus {
      opacity: 1 !important;
    }
  }
  &_criterias {
    .ant-table-bordered .ant-table-tbody > tr {
      td:nth-last-child(1) {
        text-align: left;
      }
      td:nth-child(2) {
        position: relative;
      }
    }
  }
  &_popover {
    .ant-popover-inner,
    > .ant-popover-content > .ant-popover-arrow {
      background-color: #f5f5f5;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-btn {
      width: 80px;
      display: block;
      margin: 10px auto 0;
    }
    label {
      display: block;
      margin-bottom: 10px;
    }
  }
  &_multiple {
    .ant-popover-inner p {
      margin: 0;
    }
  }
}

.osces-table {
  &_sequence {
    position: relative;
    padding: 15px 0 15px 5px;
    &:last-child {
      padding-bottom: 25px;
      border-top: 1px dashed @border-color;
    }
    &:first-child {
      border-top: none;
    }
    &_name {
      top: 15px;
      left: -20px;
      font-weight: bold;
      position: absolute;
    }
  }
  &_posts {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }
  &_post {
    width: 22%;
    margin: 5px 1.5%;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid @border-color;
    &_header {
      display: flex;
      min-height: 41px;
      font-weight: 600;
      align-items: center;
      padding: 0 8px 0 14px;
      background: @light-grey;
      justify-content: space-between;
    }
    &.selected .osces-table_post_header {
      background: #eaf3ff;
    }
    &_extra {
      width: 24px;
      height: 24px;
      display: block;
      min-width: 24px;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      border-radius: 50%;
      background: #9dff94;
      border: 1px solid #54e047;
      &.red {
        background: #ffdce0;
        border-color: #ffacac;
      }
    }
    &_body {
      padding: 5px;
      min-height: 132px;
    }
    &_patient {
      width: 100%;
      line-height: 26px;
      position: relative;
      border-radius: 4px;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      padding: 0 22px 0 9px;
      text-overflow: ellipsis;
      background: @light-grey;
      &:last-child {
        margin-bottom: 0;
      }
      &.red {
        background: #ffdce0;
      }
    }
    &_remove {
      top: 0;
      bottom: 0;
      right: 5px;
      margin: auto;
      width: 16px;
      height: 16px;
      color: #618bd1;
      font-size: 10px;
      cursor: pointer;
      line-height: 15px;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      border: 1px solid @primary-color;
    }

  }
  .ant-table-expanded-row {
    cursor: default !important;
  }
}

.btn-left {
  float: left;
}
