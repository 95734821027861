/* OSCE COLORS */
/*
 reset antd
*/
.header {
  z-index: 1;
  position: relative;
  background: #424767;
  height: 72px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}
.header_block[class*="ant-menu"] {
  line-height: 72px;
  background: transparent;
  border: none;
}
.header_block[class*="ant-menu"] .ant-menu-item {
  opacity: 0.8;
  border: none;
  padding: 0 18px;
}
.header_block[class*="ant-menu"] .ant-menu-item:hover {
  opacity: 1;
  color: #fff;
  border: none;
}
.header_block[class*="ant-menu"] .ant-menu-item > a {
  color: #fff;
  font-size: 0.9rem;
  position: relative;
  margin: 0 -18px;
  padding: 0 18px;
}
.header_block[class*="ant-menu"] .ant-menu-item > a:hover {
  color: #fff;
}
.header_block[class*="ant-menu"] .ant-menu-item-selected {
  opacity: 1;
  font-weight: 500;
}
.header_block[class*="ant-menu"] .ant-menu-item-selected > a:after {
  content: '';
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  display: block;
  background: #fff;
  position: absolute;
}
.header_right {
  display: flex;
  align-items: center;
  height: 72px;
}
.header_right.ant-dropdown-trigger {
  padding: 0;
}
.header_right_logout {
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  border-left: 1px solid #fff;
  height: 40px;
}
.header_right_logout:hover {
  opacity: 0.8;
}
.header_right_logout i {
  margin-right: 10px;
}
.header_right_logout p {
  margin-bottom: 0px;
}
.header_right_logout_username {
  height: 21px;
  font-size: 0.8rem;
  margin-top: -15px;
  line-height: 1.5;
}
.header_right_logout_roles {
  height: 21px;
  font-size: 0.7rem;
  line-height: 1.75;
  opacity: 0.8;
}
.header .navbar-top-links > li {
  padding: 0 30px;
  line-height: 40px;
}
.header .navbar-top-links > li > a {
  padding: 0;
  font-size: 0.8rem;
}
.header .navbar-top-links > li > a:hover {
  color: #fff;
  text-decoration: none;
  background: transparent;
}
.popover-header span {
  margin: 5px 0;
  display: block;
  font-size: 0.8rem;
}
