@import '../../../../../style/theme.less';
@light-blue: #ebf3ff;
@light-grey: #e9e9e9;
@light-green: #7ed321;
@light-orange: #f5a623;
@light-violet: #bd10e0;

.column {
  display: flex;
  flex-direction: column;
}

.timeSlot {
  &-field {
    margin: 5px 0;
  }
}

.move-btns {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  button {
    margin-bottom: 10px;
  }
}

.break {
  background-color: @light-blue;
  width: 100%;
  height: 19px;
  display: flex;
  &.breakFirst {
    height: 38px;
  }
  &_higher {
    height: 57px;
  }
  .break-cell {
    width: 100%;
    position: relative;
    text-align: center;
  }
  &.break_coffeeBreak {
    height: 19px;
  }
}

.column {
  width: 60px;
  border-right: 1px solid @light-grey;
  .break-cell {
    width: 100%;
    position: relative;
    text-align: center;
    background-color: @light-blue;
    height: 38px;
    min-height: 38px;
  }
  &:last-child {
    border-right: none;
  }
  &.isEmpty:not(.examiner) {
    border-right: none;
  }
}

.cell {
  width: 100%;
  min-height: 38px;
  background: white;
  position: relative;
  text-align: center;
  border-bottom: 1px solid @light-grey;
  box-sizing: border-box;
  &:not(.noFlex) {
    flex: 1;
  }
  &::after {
    content: '';
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 8px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &.disabled {
    background: #f1f1f1;
  }
}

.students {
  &.noFlexCell .cell {
    flex: initial;
  }
  &.noMaxHeight .cell {
    max-height: initial;
  }
  .cell {
    &.noMaxHeight {
      max-height: initial;
    }
    &::after {
      border-color: transparent transparent @light-green transparent;
    }
  }
  &.hasStartBreak .break-cell {
    width: 60px;
  }
}

.simulated-patient {
  .cell {
    min-height: 38px;
    &::after {
      border-color: transparent transparent @light-orange transparent;
    }
  }
  &.hasEndBreak .break-cell,
  &.hasStartBreak .break-cell {
    width: 60px;
  }
}

.examiner {
  .cell {
    min-height: 38px;
    &::after {
      border-color: transparent transparent @light-violet transparent;
    }
  }
}

.wrapper {
  .fields {
    height: 30px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-child {
      margin-right: 0;
    }
    p {
      min-width: 120px;
      margin-bottom: 0;
    }
    &_item {
      min-width: 120px;
      &_search .ant-select {
        width: 100%;
      }
    }
  }
}

.footer-btns {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  .btn {
    width: 49%;
  }
}

.popconfirm-schedule {
  i {
    display: none;
  }
  .ant-popover-message-title {
    padding-left: 0;
  }
}

@media (min-width: @desktop) {
  .column .break-cell {
    height: 44px;
    min-height: 44px;
  }
  .cell {
    min-height: 44px;
  }
  .simulated-patient .cell,
  .examiner .cell {
    height: 44px;
    min-height: 44px;
  }
}
