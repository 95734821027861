.table-panel {
  margin-bottom: 16px;
  padding-top: 10px;
}
.table-panel_btn {
  font-weight: 500;
  vertical-align: top;
}
.table-panel_filter {
  margin-left: 24px;
}
.table-panel_btn:not(:last-child) {
  margin-right: 24px;
}
.table-panel_search {
  width: 160px;
}
.table-panel .ant-input-affix-wrapper {
  width: 160px;
}
.table-panel_btn-right {
  float: right;
  margin-right: 0;
}
.table-panel_btn-right.freeze-btn {
  margin-right: 24px;
}
.table-panel_print-btn {
  margin-right: 20px;
}
.table-panel:after {
  content: '';
  clear: both;
  display: table;
}
